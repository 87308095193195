import React from 'react'
import Layout from '../components/layout'
import '../styles/privacy_terms.css'

const PrivacyPage = () => {
  return (
    <Layout footerBackgroundColor={'#F7F9FC'}>
      <section className="Main">
        <h1>Privacy Policy</h1>
        <p>Effective date: January 12th 2023</p>
        <p>At Vitalize, we take your privacy seriously. Please read this Privacy Policy to learn how we treat your personal data. By using or accessing our Services in any manner, including without limitation registering for the Services, you acknowledge that you accept the practices and policies outlined below, and you hereby consent that we will collect, use and share your information as described in this Privacy Policy. These terms apply to all users of the Services, including, without limitation, registered and unregistered users.</p>
        <p>Remember that your use of Vitalize's Services is at all times subject to our <a href="/terms">Terms of Use</a>, which incorporates this Privacy Policy. Any terms we use in this Policy without defining them, including some capitalized terms, have the definitions given to them in the Terms of Use.</p>
        <p>You may print a copy of this Privacy Policy by clicking <a href="/privacy_policy.pdf">here</a>.</p>
        <p>As we continually work to improve our Services, we may need to change this Privacy Policy from time to time. Upon such changes, we will alert you to any such changes by placing a notice on the Vitalize website, by sending you an email and/or by some other means. Please note that if you’ve opted not to receive legal notice emails from us (or you haven’t provided us with your email address), those legal notices will still govern your use of the Services, and you are still responsible for reading and understanding them. If you use the Services after any changes to the Privacy Policy have been posted, that means you agree to all of the changes.</p>

        <h2>Privacy Policy Table of Contents</h2>
        <ul>
          <li><a href="#1">What this Privacy Policy Covers</a></li>
          <li><a href="#2">Personal Data</a></li>
          <ul>
            <li><a href="#2a">Categories of Personal Data We Collect</a></li>
            <li><a href="#2b">Categories of Sources of Personal Data</a></li>
            <li><a href="#2c">Our Commercial or Business Purposes for Collecting or Disclosing Personal Data</a></li>
          </ul>
          <li><a href="#3">How We Disclose Your Personal Data</a></li>
          <li><a href="#4">Tracking Tools and Opt-Out</a></li>
          <li><a href="#5">Data Security</a></li>
          <li><a href="#6">Data Retention</a></li>
          <li><a href="#7">Personal Data of Children</a></li>
          <li><a href="#8">Other State Law Privacy Rights</a></li>
          <li><a href="#9">Contact Information</a></li>
        </ul>

        <h2 id="1">What this Privacy Policy Covers</h2>
        <p>This Privacy Policy covers how we treat Personal Data that we gather when you access or use our Services. “Personal Data” means any information that identifies or relates to a particular individual and also includes information referred to as “personally identifiable information” or “personal information” under applicable data privacy laws, rules or regulations. This Privacy Policy does not cover the practices of companies we don’t own or control or people we don’t manage.</p>

        <h2 id="2">Personal Data</h2>
        <h3 id="2a">Categories of Personal Data We Collect</h3>
        <p>This chart details the categories of Personal Data that we collect and have collected over the past 12 months:</p>

        <PersonalDataTable />

        <h3 id="2b">Categories of Sources of Personal Data</h3>
        <p>We collect Personal Data about you from the following categories of sources:</p>
        <ul>
          <li>You</li>
          <ul>
            <li>When you provide such information directly to us.</li>
            <ul>
              <li>When you create an account or use our interactive tools, including the Peer Support Chatroom and Coaching Session(s), and other Services.</li>
              <li>When you post or upload content to the website, Peer Support Chatroom or platform.</li>
              <li>When you voluntarily provide information in free-form text boxes through the Services or through responses to surveys or questionnaires.</li>
              <li>When you send us an email or otherwise contact us.</li>
            </ul>
            <li>When you use the Services and such information is collected automatically.</li>
            <ul>
              <li>Through Cookies (defined in the “Tracking Tools and Opt-Out” section below).</li>
              <li>If you download our mobile application or use a location-enabled browser, we may receive information about your location and mobile device, as applicable.</li>
              <li>If you download and install certain applications and software we make available, we may receive and collect information transmitted from your computing device for the purpose of providing you the relevant Services, such as information regarding when you are logged on and available to receive updates or alert notices.</li>
            </ul>
          </ul>
          <li>Third Parties</li>
          <ul>
            <li>Vendors</li>
            <ul>
              <li>We may use analytics providers to analyze how you interact and engage with the Services, or third parties may help us provide you with customer support.</li>
            </ul>
            <li>Social Networks</li>
            <ul>
              <li>If you provide your social network account credentials to us or otherwise sign in to the Services through a third-party site or service, some content and/or information in those accounts may be transmitted into your account with us.</li>
            </ul>
          </ul>
        </ul>
        <h3 id="2c">Our Commercial or Business Purposes for Collecting or Disclosing Personal Data</h3>
        <ul>
          <li>Providing, Customizing and Improving the Services</li>
          <ul>
            <li>Creating and managing your account or other user profiles.</li>
            <li>Providing you with the services or information you request.</li>
            <li>Meeting or fulfilling the reason you provided the information to us.</li>
            <li>Providing support and assistance for the Services.</li>
            <li>Improving the Services, including testing, research, internal analytics and product development.</li>
            <li>Personalizing the Services, website content and communications based on your preferences.</li>
            <li>Doing fraud protection, security and debugging.</li>
            <li>Carrying out other business purposes stated when collecting your Personal Data or as otherwise set forth in applicable data privacy laws.</li>
            <li>Creating and managing user accounts or other user profiles.</li>
          </ul>
          <li>Marketing the Services</li>
          <ul>
            <li>Marketing and selling the Services.</li>
          </ul>
          <li>Corresponding with You</li>
          <ul>
            <li>Responding to correspondence that we receive from you, contacting you when necessary or requested, and sending you information about Vitalize or the Services.</li>
            <li>Sending emails and other communications according to your preferences or that display content that we think will interest you.</li>
          </ul>
          <li>Meeting Legal Requirements and Enforcing Legal Terms</li>
          <ul>
            <li>Fulfilling our legal obligations under applicable law, regulation, court order or other legal process, such as preventing, detecting and investigating security incidents and potentially illegal or prohibited activities.</li>
            <li>Protecting the rights, property or safety of you, Vitalize or another party.</li>
            <li>Enforcing any agreements with you.</li>
            <li>Responding to claims that any posting or other content violates third-party rights.</li>
            <li>Resolving disputes.</li>
          </ul>
        </ul>
        <p>We will not collect additional categories of Personal Data or use the Personal Data we collected for materially different, unrelated or incompatible purposes without providing you notice.</p>

        <h2 id="3">How We Disclose Your Personal Data</h2>
        <p>We disclose your Personal Data to the categories of service providers and other parties listed in this section. Depending on state laws that may be applicable to you, some of these disclosures may constitute a “sale” of your Personal Data. For more information, please refer to the state-specific sections below.</p>
        <ul>
          <li>Service Providers. These parties help us provide the Services or perform business functions on our behalf. They include:</li>
          <ul>
            <li>Hosting, technology and communication providers.</li>
            <li>Security and fraud prevention consultants.</li>
            <li>Support and customer service vendors.</li>
            <li>Coaches.</li>
          </ul>
          <li>Analytics Partners. These parties provide analytics on web traffic or usage of the Services. They include:</li>
          <ul>
            <li>Companies that track how users interact with the Services and platform.</li>
          </ul>
          <li>Business Partners. These parties partner with us in offering various services. They include:</li>
          <ul>
            <li>Businesses that you have a relationship with. For example, we share aggregated and de-identified data and trends to users’ employers to help employers better understand their staff’s mental health trends.</li>
          </ul>
          <li>Parties You Authorize, Access or Authenticate</li>
          <ul>
            <li>Third parties you access or interact with through the services.</li>
            <li>Social media services.</li>
            <li>Other users.</li>
          </ul>
        </ul>
        <h3>Legal Obligations</h3>
        <p>We may share any Personal Data that we collect with third parties in conjunction with any of the activities set forth under “Meeting Legal Requirements and Enforcing Legal Terms” in the “Our Commercial or Business Purposes for Collecting or Disclosing Personal Data” section above.</p>
        <h3>Business Transfers</h3>
        <p>All of your Personal Data that we collect may be transferred to a third party if we undergo a merger, acquisition, bankruptcy or other transaction in which that third party assumes control of our business (in whole or in part). Should one of these events occur, we will make reasonable efforts to notify you before your information becomes subject to different privacy and security policies and practices.</p>
        <h3>Data that is Not Personal Data</h3>
        <p>We may create aggregated, de-identified or anonymized data from the Personal Data we collect, including by removing information that makes the data personally identifiable to a particular user. We may use such aggregated, de-identified or anonymized data and share it with third parties for our lawful business purposes, including to analyze, build and improve the Services and promote our business, and may share aggregated, de-identified or anonymized data with Users’ employers to help employers better understand their staff’s mental health trends.</p>

        <h2 id="4">Tracking Tools and Opt-Out</h2>
        <p>The Services use cookies and similar technologies such as pixel tags, web beacons, clear GIFs and JavaScript (collectively, “Cookies”) to enable our servers to recognize your web browser, tell us how and when you visit and use our Services, analyze trends, learn about our user base and operate and improve our Services. Cookies are small pieces of data– usually text files – placed on your computer, tablet, phone or similar device when you use that device to access our Services. We may also supplement the information we collect from you with information received from third parties, including third parties that have placed their own Cookies on your device(s). Please note that because of our use of Cookies, the Services do not support “Do Not Track” requests sent from a browser at this time.</p>
        <p>We use the following types of Cookies:</p>
        <ul>
          <li><u>Essential Cookies.</u> Essential Cookies are required for providing you with features or services that you have requested. For example, certain Cookies enable you to log into secure areas of our Services. Disabling these Cookies may make certain features and services unavailable.</li>
          <li><u>Functional Cookies.</u> Functional Cookies are used to record your choices and settings regarding our Services, maintain your preferences over time and recognize you when you return to our Services. These Cookies help us to personalize our content for you, greet you by name and remember your preferences (for example, your choice of language or region).</li>
          <li><u>Performance/Analytical Cookies.</u> Performance/Analytical Cookies allow us to understand how visitors use our Services. They do this by collecting information about the number of visitors to the Services, what pages visitors view on our Services and how long visitors are viewing pages on the Services. Performance/Analytical Cookies also help us measure the performance of our advertising campaigns in order to help us improve our campaigns and the Services’ content for those who engage with our advertising. For example, Google LLC (“Google”) uses cookies in connection with its Google Analytics services. Google’s ability to use and share information collected by Google Analytics about your visits to the Services is subject to the Google Analytics Terms of Use and the Google Privacy Policy. You have the option to opt-out of Google’s use of Cookies by visiting the Google advertising opt-out page at <a href="www.google.com/privacy_ads.html">www.google.com/privacy_ads.html</a> or the Google Analytics Opt-out Browser Add-on at <a href="https://tools.google.com/dlpage/gaoptout/">https://tools.google.com/dlpage/gaoptout/</a>.</li>
        </ul>
        <p>You can decide whether or not to accept Cookies through your internet browser’s settings. Most browsers have an option for turning off the Cookie feature, which will prevent your browser from accepting new Cookies, as well as (depending on the sophistication of your browser software) allow you to decide on acceptance of each new Cookie in a variety of ways. You can also delete all Cookies that are already on your device. If you do this, however, you may have to manually adjust some preferences every time you visit our website and some of the Services and functionalities may not work.</p>
        <p>To explore what Cookie settings are available to you, look in the “preferences” or “options” section of your browser’s menu. To find out more information about Cookies generally, including information about how to manage and delete Cookies, please visit <a href="http://www.allaboutcookies.org/">http://www.allaboutcookies.org/</a>.</p>

        <h2 id="5">Data Security</h2>
        <p>We seek to protect your Personal Data from unauthorized access, use and disclosure using appropriate physical, technical, organizational and administrative security measures based on the type of Personal Data and how we are processing that data. You should also help protect your data by appropriately selecting and protecting your username, password and/or other sign-on mechanism; limiting access to your computer or device and browser; and signing off after you have finished accessing your account. Although we work to protect the security of your account and other data that we hold in our records, please be aware that no method of transmitting data over the internet or storing data is completely secure.</p>

        <h2 id="6">Data Retention</h2>
        <p>We retain Personal Data about you for as long as necessary to provide you with our Services or to perform our business or commercial purposes for collecting your Personal Data. When establishing a retention period for specific categories of data, we consider who we collected the data from, our need for the Personal Data, why we collected the Personal Data, and the sensitivity of the Personal Data. In some cases we retain Personal Data for longer, if doing so is necessary to comply with our legal obligations, resolve disputes or is otherwise permitted or required by applicable law, rule or regulation. We may further retain information in an anonymous or aggregated form where that information would not identify you personally.</p>
        <p>For example:</p>
        <ul>
          <li>We retain your profile information and credentials for as long as you have an account with us.</li>
          <li>We retain your device/IP data for as long as we need it to ensure that our systems are working appropriately, effectively and efficiently.</li>
          <li>We retain your user data for as long as we need it to ensure that we can make improvements to the user experience of the platform and services that we offer.</li>
        </ul>

        <h2 id="7">Personal Data of Children</h2>
        <p>As noted in the Terms of Use, we do not knowingly collect or solicit Personal Data about children under 13  years of age; if you are a child under the age of 13, please do not attempt to register for or otherwise use the Services or send us any Personal Data. If we learn we have collected Personal Data from a child under 13 years of age, we will delete that information as quickly as possible. If you believe that a child under 13 years of age may have provided Personal Data to us, please contact us at <a href="mailto:team@vitalizecare.co">team@vitalizecare.co</a>.</p>

        <h2 id="8">State Law Privacy Rights</h2>
        <h3>California Resident Rights</h3>
        <p>Under California Civil Code Sections 1798.83-1798.84, California residents are entitled to contact us to prevent disclosure of Personal Data to third parties for such third parties’ direct marketing purposes; in order to submit such a request, please contact us at <a href="mailto:team@vitalizecare.co">team@vitalizecare.co</a>.</p>
        <h3>Nevada Resident Rights</h3>
        <p>If you are a resident of Nevada, you have the right to opt-out of the sale of certain Personal Data to third parties who intend to license or sell that Personal Data. You can exercise this right by contacting us at <a href="mailto:team@vitalizecare.co">team@vitalizecare.co</a> with the subject line “Nevada Do Not Sell Request” and providing us with your name and the email address associated with your account. Please note that we do not currently sell your Personal Data.</p>

        <h2 id="9">Contact Information</h2>
        <p>If you have any questions or comments about this Privacy Policy, the ways in which we collect and use your Personal Data or your choices and rights regarding such collection and use, please do not hesitate to contact us at:</p>
        <ul>
          <li>434-333-9010</li>
          <li><a href="https://vitalizecare.co">https://vitalizecare.co</a></li>
          <li><a href="mailto:team@vitalizecare.co">team@vitalizecare.co</a></li>
          <li>800 Indiana Street #460 San Francisco, CA 94107</li>
        </ul>
      </section>
    </Layout>
  )
}

const PersonalDataTable = () => {
  return (
    <div style={{ overflowX: 'auto' }}>
      <table>
        <tr>
          <th class="col1">Category of Personal Data</th>
          <th class="col2">Examples of Personal Data We Collect</th>
          <th class="col3">Categories of Third Parties With Whom We Share this Personal Data</th>
        </tr>
        <tr>
          <td>Profile or Contact Data</td>
          <td>
            <ul>
              <li>First and last name</li>
              <li>Email</li>
              <li>Phone number</li>
              <li>Unique identifiers such as passwords</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Service Providers</li>
              <li>Analytics Partners</li>
              <li>Parties You Authorize, Access or Authenticate</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>Identifiers</td>
          <td>
            <ul>
              <li>Cultural or social identifiers (for example, being a Skateboarder, a Green Bay Packers fan, an environmental activist, etc.)</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Service Providers</li>
              <li>Analytics Partners</li>
              <li>Parties You Authorize, Access or Authenticate</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>Device/IP Data</td>
          <td>
            <ul>
              <li>IP address</li>
              <li>Device ID</li>
              <li>Domain server</li>
              <li>Type of device/operating system/browser used to access the Services</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Service Providers</li>
              <li>Analytics Partners</li>
              <li>Parties You Authorize, Access or Authenticate</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>Web Analytics</td>
          <td>
            <ul>
              <li>Web page and platform interactions</li>
              <li>Statistics associated with the interaction between device or browser and the Services</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Service Providers</li>
              <li>Analytics Partners</li>
              <li>Parties You Authorize, Access or Authenticate</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>Social Network Data</td>
          <td>
            <ul>
              <li>Email</li>
              <li>Phone number</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Service Providers</li>
              <li>Analytics Partners</li>
              <li>Parties You Authorize, Access or Authenticate</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>Consumer Demographic Data</td>
          <td>
            <ul>
              <li>Age / date of birth</li>
              <li>Gender</li>
              <li>Ethnicity</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Service Providers</li>
              <li>Analytics Partners</li>
              <li>Parties You Authorize, Access or Authenticate</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>Professional or Employment-Related Data</td>
          <td>
            <ul>
              <li>Job title</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Service Providers</li>
              <li>Analytics Partners</li>
              <li>Parties You Authorize, Access or Authenticate</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>Geolocation Data</td>
          <td>
            <ul>
              <li>IP-address-based location information</li>
              <li>GPS data</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Service Providers</li>
              <li>Analytics Partners</li>
              <li>Parties You Authorize, Access or Authenticate</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>Photos, Videos and Recordings</td>
          <td>
            <ul>
              <li>Photos, videos or recordings of your environment</li>
              <li>Photos, videos or recordings of you</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Service Providers</li>
              <li>Analytics Partners</li>
              <li>Parties You Authorize, Access or Authenticate</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>Health Data</td>
          <td>
            <ul>
              <li>Health or exercise activity monitoring</li>
              <li>Mental health information you provide to us when filling out questionnaires or surveys</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Service Providers</li>
              <li>Analytics Partners</li>
              <li>Parties You Authorize, Access or Authenticate</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>Inferences Drawn From Other Personal Data Collected</td>
          <td>
            <ul>
              <li>Inferences reflecting user attributes</li>
              <li>Inferences reflecting user behavior</li>
              <li>Inferences reflecting user preferences</li>
              <li>Inferences reflecting user abilities/aptitudes</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Service Providers</li>
              <li>Analytics Partners</li>
              <li>Parties You Authorize, Access or Authenticate</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>Other Identifying Information that You Voluntarily Choose to Provide</td>
          <td>
            <ul>
              <li>Identifying information in emails, texts, letters, messages or other communications you send us or third parties via the Peer Support Chatroom or online discussions during a Coaching Session</li>
              <li>Identifying health information you may provide to us when filling out surveys or corresponding with us regarding our Services, including any feedback you may wish to share.</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Service Providers</li>
              <li>Analytics Partners</li>
              <li>Parties You Authorize, Access or Authenticate</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>SMS Text Data</td>
          <td>
            <ul>
              <li>Cell phone number</li>
              <li>Provider name</li>
              <li>Date, time and content of messages</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Service Providers</li>
              <li>Analytics Partners</li>
              <li>Parties You Authorize, Access or Authenticate</li>
            </ul>
          </td>
        </tr>
      </table>
    </div>
  )
}

export default PrivacyPage
